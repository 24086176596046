import React, { useRef } from "react";

import logo from "../images/GMA_LOGO_CIRCLE.png";
import pianoImg from "../images/bear_playing_piano.png";

function Overview(props) {
  const image = useRef();
  // console.log(image);
  return (
    <div
      style={{ columnGap: "2rem" }}
      className={!props.onBreak1 ? "" : "d-flex"}
    >
      <div
        style={{ width: !props.onBreak1 ? "100%" : "70%" }}
        className="d-flex row text-align-center align-items-center justify-content-center pb-4"
      >
        <div className="">
          <img
            style={{ width: !props.onBreak1 ? "4rem" : "10%" }}
            className="pb-3 "
            src={logo}
            alt=""
          />
        </div>

        <h2 className="featurette-heading fw-normal lh-1 h2Centered pb-1">
          Ways to Learn with Us
        </h2>
        <p
          style={{ lineHeight: "1.5rem" }}
          className="align-self-center justify-self-center mb-4 "
        >
          Specializing in 1-on-1 music lessons for children aged 5 and above, I
          provide personalized attention to each student. The main focus is to
          make the students learn the basic of music while having fun. Please,
          click on the button bellow to learn more details.
          <br />
        </p>

        <div>
          <button
            onClick={props.onGoToPrograms}
            className="btn btn-secondary mb-3"
          >
            Learn more
          </button>
        </div>
      </div>
      <img
        ref={image}
        style={{
          width: !props.onBreak1 ? "100%" : "35%",
          height: !props.onBreak1 ? "100%" : "35%",
        }}
        className=""
        src={pianoImg}
        alt=""
      />
    </div>
  );
}

export default Overview;
