import React from "react";
import logIn from "./imagesComponents/Portal login.png";

function SectionPortal({ handlePortal }) {
  return (
    <React.Fragment>
      <div className="text-center d-flex flex-column">
        <h1 className="pt-5 ">Do You Need Access To The Student Portal?</h1>
        <br />
        <img
          onClick={handlePortal}
          style={{ width: "100%" }}
          src={logIn}
          className="shadow mb-5"
        />
        <button onClick={handlePortal} className="btn btn-outline-secondary">
          Go to Student Portal
        </button>
      </div>
    </React.Fragment>
  );
}

export default SectionPortal;
