import { useMediaQuery } from "react-responsive";

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useRef } from "react";
import Terms from "./components/terms";
import SectionPortal from "./components/SectionPortal";
import ChoirProject from "./components/ChoirProject";
import Agree from "./components/Agree";
import "./App.css";

import Intro from "./components/Intro";
import Nav from "./components/Nav";
import Overview from "./components/Overview";

import { Hero } from "./components/Hero";

import Footer from "./components/footer";
import AboutUs from "./components/AboutUs";
import Picture01 from "./images/CLAUDIA-CHELSEA.png";

import profile01 from "./images/Chelsea Valdes.jpeg";
import profile02 from "./images/testimonials_pictures/Autor__3.jpg";
import profile03 from "./images/Mayra Socorro.jpg";
import profile04 from "./images/Berta_Moreno.png";

import CardInfo from "./components/CardInfo";
import Programs from "./components/Programs";
import Quote from "./components/Quote";
import CallToAction from "./components/callToAction";
import PrivacyPolicy from "./components/PrivacyPolicy";

//////////////////////////////////////////////////////////////

/// Cuales son los elementos que queremos observar?
// todas las imagenes con tag img
// REFERENCIAS
const imagesObserved = document.querySelectorAll(".imagBlured");

/// CREATE AN OBSERVER , esto recibe 2 parametros. una function (intersection) y (options)
const intersection = (entries, observer) => {
  entries.forEach((entry) => {
    console.log(entries.target);
  });
};

/// options es un objeto con propiedades necesarias para el observer
const options = {
  // cual es el viewport?
  root: null,
  //
  rootMargin: "0px",
  // si pones 0.5 en threshold se activará intersection function cuando el elemento este a la mitad o a 0.5
  threshold: 0,
};

// OBSERVER
const observer = new IntersectionObserver(intersection, options);

/// OBSERVER DEBE OBSERVAR CADA NUNA DE LAS REFERENCIAS
imagesObserved.forEach((img) => {
  observer.observe(img);
});
//////////////////////////////////////////////////////////////

const TestimonialsCards = [
  {
    name: "Chelsea Valdes",
    img: profile01,
    Description:
      "I started working with Claudia last year to learn how to use my voice again. What I admire about her teaching style is that the importance of technique and vocabulary is apparent as well as the importance of brining real emotion and art to the craft. She herself is a wonderful pianist and singer. She provides a safe space and is there to encourage you through the challenges/doubts and guides you. I have learned so much from her and she is the kind of teacher my younger self has always longed for. The way she shows her support is admirable. She reminds you to bring your heart to the craft and that it is the most important part of it all. If you’re looking for a safe space to work on and explore music- specifically through vocals and other instruments like the piano- I highly recommend learning with Claudia !",
    title: "Voice Student",
  },

  {
    name: "Laura Wilson",
    img: profile02,
    Description:
      "I enrolled my two children in piano lessons with Claudia and I am blown away by the progress they have made in just a few short months. Claudia is amazing and has a real gift for connecting with kids and making the learning process fun and engaging. My kids loves coming to her lessons and I couldn't be happier with their progress!",
    title: "Mother of piano students",
  },
  {
    name: "Mayra Socorro",
    img: profile03,
    Description:
      "Claudia Govantes is a fantastic teacher and an even better person. She helped my daugther increase her confidence for her school's musical so she would be able to sing her song to the best of her ability. Claudia helped my daughter sofia to perfect her vocal potential. She only worked with sofia for a short time but left a long-lasting impression and I know she would not be the same without claudia professional knowledge.",
    title: "Mother of Voice Student",
  },
  {
    name: "Berta Moreno",
    img: profile04,
    Description:
      "Claudia Govantes is one of the most professional, creative, and engaging teachers I have ever worked with. I was fortunate to work with Claudia at several music schools and music programs in New York City. I could see first-hand the enthusiasm and motivation of all of her students after the classes and the high-level musicality and technical development on their instruments.",
    title:
      "Multi-awarded jazz saxophonist, composer, bandleader, and educator.",
  },
];

function App() {
  const [childState, setChildState] = useState(false);
  const [gmaStatus, setGmaStatus] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);

  // const [exitTime, setExitTime] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const handleTerms = (e) => {
    e.preventDefault();
    setIsPolicy(false);
    setIsTerms(true);
    // Scroll to the top of the page where the 'termsUp' ref is located
    return termsUp.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePolicy = (e) => {
    e.preventDefault();
    setIsPolicy(true);
    setIsTerms(false);
    // Scroll to the top of the page where the 'termsUp' ref is located
    return termsUp.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePortal = () => {
    window.open("https://portal.govantesmusicacademy.com/", "_blank");
  };

  window.addEventListener("load", async function () {
    // console.log("Enter onload");
    let location;
    let entryTime;
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    let userIP = ipData.ip;
    const locationResponse = await fetch(`https://ipapi.co/${userIP}/json/`);
    const locationData = await locationResponse.json();
    location = locationData;

    function formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }

    function formatTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }

    // window.onbeforeunload = () => {
    //   setExitTime(new Date());
    // };

    ////

    // Record the entry time
    entryTime = new Date();
    const dateFormatted = formatDate(entryTime);
    const timeFormatted = formatTime(entryTime);

    const dataIn = {
      dateFormatted,
      timeFormatted,
      userIP,
      city: location.city,
      country: location.country,
      postal: location.postal,
    };

    // console.log(dataIn);

    // Send data to your server
    const response = await fetch(
      `https://17i084pyg6.execute-api.us-east-1.amazonaws.com/submit-data`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataIn),
      }
    );

    if (response.ok) {
      console.log("Form submitted successfully");
    } else {
      console.error("Form submission failed");
    }
    // } catch (error) {
    //   console.error("Error occurred:", error);
    // }
  });

  //////  //////  //////  //////

  const [childrenChoirStatus, setChildrenChoirStatus] = useState(false);

  const scrollToRef = useRef();
  const aboutRef = useRef();
  const termsUp = useRef();
  // const policyUp = useRef();

  function goToPrograms() {
    scrollToRef.current.scrollIntoView();
  }

  function goToAbout() {
    aboutRef.current.scrollIntoView();
  }

  const break1 = useMediaQuery({
    query: "(min-width: 650px)",
  });

  const break3 = useMediaQuery({
    query: "(max-width: 1023px)",
  });

  const sectionProperty = {
    display: break1 ? "flex" : "block",
    justifyContent: "center",
    alignContent: "center",

    width: "100%",
    padding: break1 ? "2rem" : "0rem",
    paddingLeft: break1 ? "10vw" : "0rem",
    paddingRight: break1 ? "10vw" : "0rem",
  };

  return (
    <React.Fragment>
      <main ref={termsUp}>
        <Nav
          className="navbar fixed-top "
          onGoToAbout={goToAbout}
          onBreak1={break1}
          onGoToPrograms={goToPrograms}
          onChildState={childState}
          onSetChildState={setChildState}
          handlePortal={handlePortal}
        />

        {!isTerms && !isPolicy && (
          <React.Fragment>
            <Hero
              onGoToPrograms={goToPrograms}
              handlePortal={handlePortal}
              onBreak1={break1}
            />

            <div>
              <section style={sectionProperty}>
                <div className={!break1 ? "margenes " : ""}>
                  <Overview onGoToPrograms={goToPrograms} onBreak1={break1} />
                </div>

                <br />
                <br />
              </section>
              <hr />
              <section style={sectionProperty}>
                <div className={!break3 ? "chelseaGrid " : ""}>
                  <img className="claudia_chelsea" src={Picture01} />
                  <Quote
                    onImage={TestimonialsCards[0].img}
                    onQuote={TestimonialsCards[0].Description}
                    onTitle={TestimonialsCards[0].title}
                    onName={TestimonialsCards[0].name}
                  />
                </div>
              </section>
              <hr ref={aboutRef} />
              <section style={sectionProperty}>
                <div className="ps-3 pe-3">
                  <div className={break1 ? "" : "margenes"}>
                    <AboutUs />
                  </div>
                </div>
              </section>
              <section style={sectionProperty}>
                <div className={break1 ? "" : "margenes"}>
                  <CardInfo />
                </div>
              </section>
              <div>
                <div style={{ width: "60vw" }} className="d-grid  mx-auto m-4">
                  <CallToAction />
                </div>
                <br />
                {/* <hr /> */}
              </div>

              <hr />

              {!break1 && (
                <React.Fragment>
                  <section style={sectionProperty}>
                    <div className="m-3 align-content-center">
                      <SectionPortal handlePortal={handlePortal} />
                    </div>
                  </section>
                  <hr />
                  <section style={sectionProperty}>
                    <div className="m-3 align-content-center">
                      <ChoirProject />
                    </div>
                  </section>
                </React.Fragment>
              )}

              {break1 && (
                <div style={{ margin: "8vw" }}>
                  <div className="d-flex gap-3 m-3  ">
                    <section>
                      <div className=" align-content-center m-3">
                        <SectionPortal handlePortal={handlePortal} />
                      </div>
                    </section>
                    <section>
                      <div className="align-content-center  m-3 ">
                        <ChoirProject />
                      </div>
                    </section>
                  </div>
                </div>
              )}
              <hr />

              <section style={sectionProperty}>
                <div>
                  <Quote
                    onImage={TestimonialsCards[1].img}
                    onQuote={TestimonialsCards[1].Description}
                    onTitle={TestimonialsCards[1].title}
                    onName={TestimonialsCards[1].name}
                  />
                </div>
              </section>
              <hr />
              <section style={sectionProperty}>
                <div>
                  <Quote
                    onImage={TestimonialsCards[2].img}
                    onQuote={TestimonialsCards[2].Description}
                    onTitle={TestimonialsCards[2].title}
                    onName={TestimonialsCards[2].name}
                  />
                </div>
              </section>
              <hr />
              <section ref={scrollToRef} style={sectionProperty}>
                <Programs onBreak1={break1} />
              </section>
            </div>
            <section style={sectionProperty}>
              <div>
                <Quote
                  // style={{ flexGrow: "1", flexBasis: "0", width: "50%" }}
                  onImage={TestimonialsCards[3].img}
                  onQuote={TestimonialsCards[3].Description}
                  onTitle={TestimonialsCards[3].title}
                  onName={TestimonialsCards[3].name}
                />
              </div>
            </section>

            <Footer
              handlePolicy={handlePolicy}
              // setIsPolicy={setIsPolicy}
              handlePortal={handlePortal}
              handleTerms={handleTerms}
              onBreak1={break1}
            />
          </React.Fragment>
        )}

        {isTerms && !isPolicy && <Terms handleTerms={handleTerms} />}
        {isTerms && !isPolicy && (
          <Footer
            handlePolicy={handlePolicy}
            // setIsPolicy={setIsPolicy}
            handlePortal={handlePortal}
            handleTerms={handleTerms}
            onBreak1={break1}
          />
        )}

        {isPolicy && <PrivacyPolicy />}
        {isPolicy && (
          <Footer
            handlePolicy={handlePolicy}
            // setIsPolicy={setIsPolicy}
            handlePortal={handlePortal}
            handleTerms={handleTerms}
            onBreak1={break1}
          />
        )}
      </main>
      {!isAccepted && (
        <Agree
          setGmaStatus={setGmaStatus}
          setIsPolicy={setIsPolicy}
          setIsAccepted={setIsAccepted}
        />
      )}
    </React.Fragment>
  );
}

export default App;
