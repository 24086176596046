import React from "react";
import littlesProgramImg from "../images/bg_three_musicians_reduced.png";

function Programs(props) {
  const marginCard = !props.onBreak1 ? "card mb-2" : "card ";
  const styleCard = { height: "100%" };
  return (
    <React.Fragment>
      <div style={{ border: "none" }} className="card text-center shadow">
        {/* <div className="card-header">PR</div> */}
        <div className="card-body">
          <h2 className="card-title">Personalized Piano & Vocal Instruction</h2>
          <br />
          {/* <p className="card-text">These are the music programs we offer.</p> */}

          <div
            style={{
              display: "grid",
              rowGap: !props.onBreak1 ? "0rem" : "1rem",
            }}
            className=" text-dark "
          >
            <div className={!props.onBreak1 ? "" : "row "}>
              <div className="col">
                <div style={styleCard} className={marginCard}>
                  <p className=" card-header fw-bold bg-dark text-light">
                    One-on-One
                  </p>
                  <div className="card-body">
                    <p className=" fz-md card-text">
                      Offering one-on-one lessons tailored to the child pace and
                      preference.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div style={styleCard} className={marginCard}>
                  <p className="card-header fw-bold bg-dark text-light">
                    Foundation in Music Theory
                  </p>

                  <div className="card-body">
                    <p className=" fz-md card-text">
                      Beyond just playing and having fun, the child will gain a
                      solid grounding in basic music knowledge. From
                      understanding notes, scales, and chords to grasping rhythm
                      and melody, I provide the tools to appreciate and
                      interpret music more deeply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={!props.onBreak1 ? "" : "row "}>
              <div className="col">
                <div style={styleCard} className={marginCard}>
                  <p className="card-header fw-bold bg-dark text-light">
                    Flexible Scheduling
                  </p>

                  <div className="card-body">
                    <p className=" fz-md card-text">
                      Our lessons can be scheduled at your convenience, making
                      it easy for you to integrate music into your busy life.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={styleCard} className={marginCard}>
                  <p className="card-header fw-bold bg-dark text-light">
                    Progress Tracking
                  </p>

                  <div className="card-body">
                    <p className=" fz-md card-text">
                      Regular feedback and assessments will help you see your
                      progress, keeping you motivated and informed about areas
                      that might need a bit more attention.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="card-footer text-body-secondary">2 days ago</div> */}

        {/* <div className="margenes border rounded mb-3"> */}
        {/* <h1>"Music Learning For Littles"</h1> */}
        {/* <h1>Program</h1> */}
        {/* <p style={{ textAlign: "left", padding: "1rem" }}>
            Our program “Music Learning For Littles” focuses on introducing
            children to music. We incorporate bilingual (English-Spanish) songs
            in our lessons, providing children with the opportunity to learn new
            words and phrases in another language while singing along to catchy
            tunes.
            <br />
            <br />
            Also, we includes a variety of games and activities that promote
            creativity, teamwork, and motor skills development. We use
            interactive games that involve movement, singing, and clapping to
            keep children engaged and energized throughout the session.
            <br />
            <br />
            Percussion instruments are an essential part of our program as they
            provide children with the opportunity to explore the rhythm and beat
            of music. We introduce children to a range of percussion instruments
            such as drums, shakers, and tambourines, allowing them to experiment
            and create their unique sounds.
            <br />
            <br />
            This is a program that create a supportive and encouraging
            environment that fosters creativity, confidence, and a love for
            music. Children will have developed their language skills, musical
            abilities, and social skills while having fun and making new
            friends. Our music program is an excellent addition to any daycare's
            curriculum, providing a unique and engaging experience for children.
          </p> */}
        {/* </div> */}
      </div>
      {/* <img
        style={{ width: "100%", marginBottom: "2rem" }}
        src={littlesProgramImg}
        alt="Music Learning for littles"
      /> */}
    </React.Fragment>
  );
}

export default Programs;
