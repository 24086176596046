import React from "react";
function Agree({ setIsAccepted, setIsPolicy, setGmaStatus }) {
  function closeStickyComponent() {
    
    return setIsAccepted(true);
  }

  const privacyActive = () => {
    setGmaStatus(true);
    return setIsPolicy(true);
  };
  return (
    <React.Fragment>
      <div id="stickyComponent" className="sticky-banner">
        <p id="message">
          Welcome to GMA! We use essential cookies and collect information to
          enhance your browsing experience. By clicking "I Accept," you consent
          to our use of these technologies as outlined in our Privacy Policy.
          Please note, access to our website's full features requires this
          consent. If you wish to learn more or adjust your preferences, please
          visit our{" "}
          <a>
            <strong onClick={privacyActive}>Privacy Policy</strong>
          </a>
          .
        </p>
        <button onClick={closeStickyComponent} className="btn btn-primary">
          I Accept
        </button>
      </div>
    </React.Fragment>
  );
}

export default Agree;
