import React from "react";
import styles from "./terms.module.css";
function Terms() {
  return (
    <React.Fragment>
      <hr />

      <div
        // style={{ margin: "0px 300px 200px" }}
        className="sectionWrapper d-flex align-items-center"
      >
        <section className="p-4 termsSection">
          <h1 className="pt-5 pb-5">
            <strong>Terms of Service for Govantes Music Academy</strong>
          </h1>
          <div>
            <strong>1. Introduction</strong>
            <br /> Welcome to Govantes Music Academy, a specialized division of
            Uptown Bubble Music LLC. We offer personalized piano and voice
            lessons to students of various ages and levels, delivered both
            online and in-person in the Inwood, NY area.
          </div>
          <div>
            <br />
            <strong>2. Services Offered Piano Lessons:</strong>
            <br />
            Individual and group instruction for all skill levels. Voice
            Lessons: Customized training in diverse vocal techniques. Sessions
            are available in 30, 45, and 60-minute durations.
          </div>
          <br />
          <div>
            <strong>3. Enrollment and Scheduling</strong>
            <br />
            Open enrollment with classes subject to availability. Flexible
            scheduling options based on student and instructor availability.
          </div>
          <div>
            <br />
            <strong>4. Payment Terms</strong>
            <br />
            Uptown Bubble Music LLC, on behalf of Govantes Music Academy, offers
            flexible payment options for the convenience of our students and
            their families. You can choose to pay for classes either per session
            or on a monthly basis.
            <br />
            <br />
            <ul>
              <li>
                <strong>Per Class Payment:</strong> Payment is due at the time
                of each class. The fee per class is to be determined based on
                the length and type of the lesson.
              </li>
              {/* <br /> */}
              <li>
                <strong>Monthly Payment:</strong> For those who prefer a regular
                schedule, we offer a monthly payment option. Fees are billed
                monthly and are due at the beginning of each month. This option
                may include a discount compared to the per-class rate, as it
                guarantees a consistent schedule and easier administration.
              </li>
              {/* <br /> */}
              <li>
                <strong>Late Payment Fee:</strong> Per Class Payment is due at
                the time of each class. A late fee of $15 will be applied to
                per-class payments made later than a 5 days after the class
                date.
                <br /> A late fee of $25 will be applied to monthly payments
                made after the first week of the month.
              </li>
            </ul>
          </div>
          <div>
            <br />
            <strong>5. Cancellation and Rescheduling</strong>
            <br />
            24-hour notice is required for cancellation or rescheduling.
            Rescheduling is subject to availability. In case of no availability
            for a make-up class in the current period, the payment may be
            refunded or applied to the next class or month's payment.
          </div>
          <div>
            <br />
            <strong>6. Online Lesson Guidelines</strong>
            <br />
            Conducted via Zoom. Students responsible for reliable internet and
            proper equipment.
          </div>
          <div>
            <br />
            <strong>7. In-Person Lesson Protocols</strong>
            <br />
            Held at 690 Academy st #4F New York NY 10034. Adherence to health
            and safety guidelines is mandatory.
          </div>
          <div>
            <br />
            <strong> 8. Progress Evaluation and Performances</strong>
            <br />
            Periodic assessments to monitor progress. Annual performance
            opportunities for interested students.
          </div>
          <div>
            <br />
            <strong>9. Conduct and Ethics</strong>
            <br />
            Professional and respectful interaction required at all times. Zero
            tolerance for harassment or discrimination.
          </div>

          <div>
            <br />
            <strong>10. Amendments to Terms</strong>
            <br />
            Uptown Bubble Music LLC reserves the right to modify these terms
            with prior notice.
          </div>
          <div>
            <br />
            <strong>11. Contact and Queries</strong>
            <br />
            For inquiries, please contact us at{" "}
            <a href="mailto:govantesmusicacademy@gmail.com">
              <strong>govantesmusicacademy@gmail.com</strong>
            </a>{" "}
            or call us at {`(786) 599-9733`}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Terms;
