import React, { useState } from "react";

import profilePicClaudia from "../images/claudia-headshot2020-02-scaled.jpeg";

import classes from "./whoWeAre.module.css";
import styles from "./CardInfo.module.css";

function CardInfo() {
  const [showBio, setShowBio] = useState(false);

  const bioHandler = (e) => {
    e.preventDefault();
    setShowBio((prev) => !prev);
  };
  return (
    <div>
      <div
        className="card mb-3 shadow"
        style={{ maxWidth: "100%", border: "none" }}
      >
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={profilePicClaudia}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Claudia Govantes</h5>
              <p
                style={{ fontSize: "0.8rem", textDecoration: "underline" }}
                className="card-text"
              >
                Founder of Govantes Music Academy
              </p>
              <p>
                Claudia Govantes is an award-winning singer and pianist based in
                New York City. She has been teaching for more than 19 years,
                participating in numerous programs as a pianist accompanist
                teacher in the Afro Latin Jazz Alliance (ALJA) directed by
                Arturo O'Farrill, Bold Arts directed by Jenn Eisenberg and
                National Dance Institute (NDI) founded by Jacques d’Amboise as
                well as a music teacher in several schools in Cuba, Florida, New
                Jersey, and New York.
              </p>

              {showBio && (
                <p>
                  After graduating from "Amadeo Roldan", one of the most
                  prominent music conservatories in Cuba, she had a long career
                  as a profesional singer. Govantes was first soprano of the
                  National Choir of Cuba as well as other choirs in the island
                  and United States such as Musica Viva NY. For more than 8
                  years, Claudia made tours to Colombia, Spain, Ecuador, United
                  States, Venezuela and Germany.
                  <br />
                  <br />
                  She participated in international contests such as
                  Torrevieja’s International Habaneras, Polyphony contest in
                  Alicante Spain, America Cantat 7 Bogota Colombia, D’CANTO
                  International Festival Margarita Island in Venezuela, where
                  her choir won the award in the category of female voices. In
                  2011, with Entrevoces choir, directed by Digna Guerra, she won
                  the First Prize at International Chamber Choir Competition
                  Marktoberdorf in Germany.
                  <br />
                  <br />
                  With Cuban early music ensemble Ars Longa directed by Teresa
                  Paz, Claudia performed several times for instance in Esteban
                  Salas early Music Festival in 2005. During 2013-2015, she was
                  choir director at Theological Baptist Seminary of Havana.
                  <br />
                  <br />
                  Claudia Govantes has been part of albums such as "El canto
                  quiere ser luz" (Singing wants to be light) and "Oh Yes!" by
                  National Choir of Cuba, "Apunte" by Ensemble Vocal Luna,
                  "Pedro Bermudez, Música en la catedral de Guatemala" (Pedro
                  Bermudez, Music in the Cathedral of Guatemala) by Ars Longa de
                  la Habana and others.
                </p>
              )}
              <a onClick={bioHandler} href="#" className="btn btn-secondary ">
                {!showBio ? "Learn more about Claudia" : "Show Less"}
              </a>
              <p className="card-text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardInfo;
