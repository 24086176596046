import React from "react";

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <hr />

      <div
        // style={{ margin: "0px 300px 200px" }}
        className="sectionWrapper d-flex align-items-center"
      >
        <section className="p-4 termsSection">
          <h1 className="pt-5 pb-5">
            <strong>Privacy Policy for Govantes Music Academy</strong>
          </h1>
          <div>
            <strong>Introduction</strong>
            <br /> Welcome to Govantes Music Academy, located in Inwood, New
            York City. We specialize in providing piano and voice lessons to a
            wide range of clients, including children from the age of 5. This
            Privacy Policy outlines how we collect, use, and protect personal
            information to understand and serve our potential clients, including
            young learners. .
          </div>
          <div>
            <br />
            <h2>Information Collection</h2>
            {/* <br /> */}
            <strong>Website Interaction:</strong>
            When you visit our website, we automatically gather certain
            information such as your IP address, browser type, and usage
            details.
          </div>
          <div>
            <br />
            <strong>Inquiries and Registration:</strong>
            <br />
            Information you provide when inquiring about our services or
            registering for lessons, including your name, contact details, and
            any other pertinent information about your lessons.
          </div>

          <br />
          <div>
            <h2>Use of Information </h2>
            <strong>The information we collect is used to:</strong>
            <br />
            Enhance and personalize your experience on our website. Communicate
            with you about our services and respond to your inquiries.
            Understand our client base to improve our services, especially to
            cater to our young learners.
          </div>
          <div>
            <br />
            <strong>Data Protection:</strong>
            <br />
            We are committed to protecting the security of your information and
            have implemented appropriate measures to prevent unauthorized
            access, disclosure, alteration, or destruction of personal
            information.
            <br />
          </div>
          <div>
            <br />
            <strong>Sharing of Information:</strong>
            <br />
            Your personal information is not sold, traded, or otherwise
            transferred to outside parties. We may share generic aggregated
            demographic information not linked to any personal identification
            information regarding visitors and users with our business partners
            and trusted affiliates for the purposes outlined above.
          </div>
          <div>
            <br />
            <strong>Cookies:</strong>
            <br />
            Our website may use "cookies" to enhance user experience. You have
            the option to set your web browser to refuse cookies or to alert you
            when cookies are being sent.
          </div>
          <div>
            <br />
            <strong>Children's Privacy:</strong>
            <br />
            We recognize the importance of protecting the privacy of children.
            For clients under the age of 13 (or a higher age threshold as
            prescribed by applicable law), we collect personal information only
            with parental or guardian consent. If we discover that personal
            information from children under 13 has been collected without
            verifiable parental consent, we will take steps to delete such
            information.
          </div>
          <div>
            <br />
            <strong> Your Rights:</strong>
            <br />
            You have the right to access, correct, or delete your personal
            information. Please contact us if you wish to exercise these rights.
          </div>
          <div>
            <br />
            <strong>Changes to This Privacy Policy:</strong>
            <br />
            We may update this Privacy Policy from time to time. Any changes
            will be notified by posting the new Privacy Policy on this page.
          </div>
          <div>
            <br />
            <strong>Contact Us:</strong>
            <br />
            For any questions about this Privacy Policy or our handling of
            personal information, please contact us at{" "}
            <a href="mailto:govantesmusicacademy@gmail.com">
              <strong>govantesmusicacademy@gmail.com</strong>
            </a>{" "}
            or call us at {`(786) 599-9733`}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;

// Privacy Policy for [Your Business Name]

// Last Updated: [Date]

// Introduction:
// Welcome to [Your Business Name], located in Inwood, New York City. We specialize in providing piano and voice lessons to a wide range of clients, including children from the age of 5. This Privacy Policy outlines how we collect, use, and protect personal information to understand and serve our potential clients, including young learners.

// Information Collection:
// We collect information as follows:

// Website Interaction: When you visit our website, we automatically gather certain information such as your IP address, browser type, and usage details.
// Inquiries and Registration: Information you provide when inquiring about our services or registering for lessons, including your name, contact details, and any other pertinent information about your lessons.
// Use of Information:
// The information we collect is used to:

// Enhance and personalize your experience on our website.
// Communicate with you about our services and respond to your inquiries.
// Understand our client base to improve our services, especially to cater to our young learners.
// Data Protection:
// We are committed to protecting the security of your information and have implemented appropriate measures to prevent unauthorized access, disclosure, alteration, or destruction of personal information.

// Sharing of Information:
// Your personal information is not sold, traded, or otherwise transferred to outside parties. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners and trusted affiliates for the purposes outlined above.

// Cookies:
// Our website may use "cookies" to enhance user experience. You have the option to set your web browser to refuse cookies or to alert you when cookies are being sent.

// Children's Privacy:
// We recognize the importance of protecting the privacy of children. For clients under the age of 13 (or a higher age threshold as prescribed by applicable law), we collect personal information only with parental or guardian consent. If we discover that personal information from children under 13 has been collected without verifiable parental consent, we will take steps to delete such information.

// Your Rights:
// You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.

// Changes to This Privacy Policy:
// We may update this Privacy Policy from time to time. Any changes will be notified by posting the new Privacy Policy on this page.

// Contact Us:
// For any questions about this Privacy Policy or our handling of personal information, please contact us at [Your Contact Information].
