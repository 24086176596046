import React, { useEffect, useState } from "react";
import logo from "../images/GMA_LOGO_CIRCLE.png";
function Nav(props) {
  const [isClicked, SetIsClicked] = useState(false);
  useEffect(() => {
    if (isClicked) {
      // Perform your action here
      console.log("Action performed");
      SetIsClicked(true);
      // setPerformAction(false); // Reset the flag
    }
  }, [isClicked, SetIsClicked]);

  return (
    <div>
      <nav className="navbar bg-body-tertiary navbar-expand-lg bg-body-tertiary  d-flex justify-content-between">
        <div className="d-flex  align-items-center ">
          <a
            className="navbar-brand "
            href="https://www.govantesmusicacademy.com/"
            style={{ fontSize: "0.9rem" }}
          >
            <img
              src={logo}
              alt="Logo"
              width="35"
              height="35"
              className="d-inline-block ms-3 "
            />
          </a>
          <p style={{ margin: "0" }}>Govantes Music Academy</p>
        </div>

        <div className=" d-flex pe-4">
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div style={{ columnGap: "2rem" }} className="navbar-nav">
              <a
                className="nav-link"
                href="mailto:govantesmusicacademy@gmail.com?subject=Schedule%20a%20Class."
              >
                Schedule a Lesson
              </a>
              <a
                style={{ cursor: "pointer" }}
                className="nav-link"
                onClick={props.handlePortal}
              >
                Student Portal
              </a>

              <a
                href="#"
                style={{ cursor: "pointer" }}
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  props.onGoToAbout();
                }}
              >
                About Us
              </a>

              <a style={{ cursor: "pointer" }} className="nav-link" href="#">
                Contact
              </a>
              {/* <a
                href="https://choir.govantesmusicacademy.com/"
                target="__blank"
                style={{ cursor: "pointer" }}
                className="nav-link"
              >
                Children's Choir Project
              </a> */}
            </div>
          </div>
        </div>

        {!props.onBreak1 && (
          <div className="pe-3">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            > */}
            {/* <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              /> */}
            {/* </svg> */}
          </div>
        )}
      </nav>
    </div>
  );
}

export default Nav;
