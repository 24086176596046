import React from "react";
import CallToAction from "./callToAction";
import bgPicture from "../images/bg_instruments.png";

export function Hero(props) {
  return (
    <div>
      <div className="header">
        <img
          className="img"
          style={{ width: "100vw", minHeight: "100vh" }}
          src={bgPicture}
          alt=""
        />
        <div className={!props.onBreak1 ? "centered p-2" : "centeredOpen p-2"}>
          <h1 style={{ fontSize: "3rem" }} className="">
            Welcome to GMA
          </h1>
          {/* <div style={{ backgroundColor: "rgba(250,250, 250, 0.7)" }}>
            <p className="fw-medium   p-1  ">
              Cultivating Passions, Nurturing Journeys in Music. Join Us Today!
            </p>
          </div> */}

          <br />
          <div
            style={{
              display: "flex",
              width: "100%",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
            // style={{
            //   paddingLeft: !props.onBreak1 ? "1rem" : "4rem",
            //   paddingRight: !props.onBreak1 ? "1rem" : "4rem",
            // }}
            // className={"p-4"}
            >
              <CallToAction />
            </div>
          </div>

          <div>
            <button onClick={props.onGoToPrograms} className="btn fw-bolder">
              Learn More
            </button>
            <button onClick={props.handlePortal} className="btn fw-bolder">
              Student Portal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
