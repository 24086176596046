import React from "react";
import { useState } from "react";

function Intro({ onSetGmaStatus, onSetChildrenChoirStatus }) {
  const [isGMA, setIsGMA] = useState(false);
  const [isChildrenChoir, setIsChildrenChoir] = useState(false);

  const gmaHandle = (e) => {
    e.preventDefault();
    setIsGMA(true);
    onSetGmaStatus(true);
  };
  const childrenChoirHandle = (e) => {
    e.preventDefault();
    window.location.href = "https://choir.govantesmusicacademy.com/";
    setIsChildrenChoir(true);
    onSetChildrenChoirStatus(true);
  };

  return (
    <main>
      {!isGMA && !isChildrenChoir && (
        <div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh", flexDirection: "column" }}
          >
            <p className="btnContainer d-flex align-items-center">
              Where Do You Want To Go?
            </p>
            <div className="btnContainer d-flex align-items-center">
              <div className="d-grid gap-2 mx-auto">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={childrenChoirHandle}
                >
                  Children's Choir Registration
                </button>
                <button
                  // href="https://www.govantesmusicacademy.com/"
                  // target="_blank"
                  onClick={gmaHandle}
                  className="btn btn-primary"
                  type="button"
                >
                  Govantes Music Academy Website
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default Intro;
