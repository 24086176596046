import React from "react";
import choir from "./imagesComponents/Choir.png";

function ChoirProject() {
  return (
    <React.Fragment>
      <div className="text-center d-flex flex-column">
        <h1 className="pt-5 ">Children's Choir Registration</h1>
        <br />
        <img className="shadow mb-5" style={{ width: "100%" }} src={choir} />
        <button
          onClick={() => {
            window.location.href = "https://choir.govantesmusicacademy.com";
          }}
          className="btn btn-outline-secondary"
        >
          Go to Registration Page
        </button>
      </div>
    </React.Fragment>
  );
}

export default ChoirProject;
