import React from "react";
import classes from "./callToAction.module.css";

function CallToAction() {
  return (
    <div className={classes.btnCallToAction}>
      <a
        style={{ width: "100%" }}
        href="mailto:govantesmusicacademy@gmail.com?subject=Schedule%20a%20Class."
        type="button"
        className="btn btn-dark ps-5 pe-5 pt-2 pb-2 fw-semibold"
      >
        Schedule a Class
      </a>
    </div>
  );
}

export default CallToAction;
