import React from "react";
import Testimonials from "./Testimonials";
import CallToAction from "./callToAction";

function AboutUs() {
  return (
    //style={{ maxWidth: "40rem" }}
    <div className="">
      <h2 className="h2Centered">My Values & Mission</h2>

      <p>
        My teaching approach is rooted in a deep respect for diversity and the
        power of music to enrich and transform lives. That's why my mission is
        to provide high quality lessons designed to be primarily fun, as well as
        engaging and chanllenging, providing each student with a comprehensive
        music education that will serve them well for a lifetime.
        <br />
        <br />
        Multiple scientific studies highlight the profound impact of music
        education on children's cognitive and emotional development. From
        enhancing IQ and reading skills to fostering a deeper emotional
        understanding, music lessons not only cultivate musical talents but also
        contribute to personal growth.
      </p>
    </div>
  );
}

export default AboutUs;
