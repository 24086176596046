import React from "react";

function Quote(props) {
  return (
    <React.Fragment>
      <figure className="text-center p-1 ">
        <blockquote className="blockquote p-4">
          <div className="fz-md pt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
            </svg>

            <p style={{ lineHeight: "18px" }}>{props.onQuote}</p>
          </div>
        </blockquote>
        <img
          style={{ width: "5rem", borderRadius: "200px" }}
          src={props.onImage}
        />

        <div className="m-3 p-1 fs-6">
          <figcaption className="blockquote-footer">
            <strong>{props.onName}</strong> |{" "}
            <cite title="Source Title">{props.onTitle}</cite>
          </figcaption>
        </div>
      </figure>
    </React.Fragment>
  );
}

export default Quote;
